import React, { useState, useEffect, useContext } from 'react'
import Builds from '../Builds/Builds'
import ExpansionDropdown from './Dropdowns/ExpansionDropdown'
import InstanceTypeDropdown from './Dropdowns/InstanceTypeDropdown'
import InstanceDropdown from './Dropdowns/InstanceDropdown'
import DifficultyDropdown from './Dropdowns/DifficultyDropdown'
import { InstanceContext, ExpansionContext } from '../../contexts/Context'
import { useParams } from 'react-router-dom'
function MainMenu() {
  const { buildguid: activeBuild } = useParams()

  const [, setActiveInstance] = useContext(InstanceContext)
  let storedActiveExpansion = 514 // TWW
  const itemActiveExpansion = localStorage.getItem('activeExpansion')
  if (itemActiveExpansion) {
    try {
      storedActiveExpansion = JSON.parse(itemActiveExpansion)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [activeExpansion, setActiveExpansion] = useState<number>(storedActiveExpansion)

  useEffect(() => {
    localStorage.setItem('activeExpansion', JSON.stringify(activeExpansion))
  }, [activeExpansion])

  let storedInstanceType = 2 // 2 = raid, 1 = dungeons
  const itemInstanceType = localStorage.getItem('instanceType')
  if (itemInstanceType) {
    try {
      storedInstanceType = JSON.parse(itemInstanceType)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [instanceType, setInstanceType] = useState(storedInstanceType)
  useEffect(() => {
    localStorage.setItem('instanceType', JSON.stringify(instanceType))
  }, [instanceType])

  const updateInstanceType = (id: number): void => {
    if (id === instanceType) {
      return
    }
    setInstanceType(id)
    setActiveInstance(0)
  }

  return (
    <div id="mainMenu">
      <Builds />
      {activeBuild !== '' && (
        <div id="dropdowns">
          <ExpansionContext.Provider value={[activeExpansion, setActiveExpansion]}>
            <ExpansionDropdown />
            <InstanceTypeDropdown updateInstanceType={updateInstanceType} instanceType={instanceType} />
            <InstanceDropdown instanceType={instanceType} />
            <DifficultyDropdown />
          </ExpansionContext.Provider>
        </div>
      )}
    </div>
  )
}

export default MainMenu
