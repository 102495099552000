const DB_NAMES = [
  'AccountStoreCategory',
  'AccountStoreItem',
  'Achievement',
  'Achievement_Category',
  'Achievement_Criteria',
  'ActionBarGroup',
  'ActionBarGroupEntry',
  'AdventureJournal',
  'AdventureJournalItem',
  'AdventureMapPOI',
  'AlliedRace',
  'AlliedRaceRacialAbility',
  'AltMinimap',
  'AltMinimapFiledata',
  'AltMinimapWMO',
  'AnimaCable',
  'AnimaCylinder',
  'AnimaMaterial',
  'AnimationData',
  'AnimationNames',
  'AnimKit',
  'AnimKitBoneSet',
  'AnimKitBoneSetAlias',
  'AnimKitConfig',
  'AnimKitConfigBoneSet',
  'AnimKitPriority',
  'AnimKitReplacement',
  'AnimKitSegment',
  'AnimReplacement',
  'AnimReplacementSet',
  'AoiBox',
  'AreaAssignment',
  'AreaConditionalData',
  'AreaFarClipOverride',
  'AreaGroup',
  'AreaGroupMember',
  'AreaMIDIAmbiences',
  'AreaPOI',
  'AreaPOISortedWorldState',
  'AreaPOIState',
  'AreaPOIUiWidgetSet',
  'AreaTable',
  'AreaTrigger',
  'AreaTriggerActionSet',
  'AreaTriggerBoundedPlane',
  'AreaTriggerBox',
  'AreaTriggerCreateProperties',
  'AreaTriggerCylinder',
  'AreaTriggerDisk',
  'AreaTriggerSphere',
  'ArenaCcItem',
  'ArenaTrackedItem',
  'ArmorLocation',
  'Artifact',
  'ArtifactAppearance',
  'ArtifactAppearanceSet',
  'ArtifactCategory',
  'ArtifactItemToTransmog',
  'ArtifactPower',
  'ArtifactPowerLink',
  'ArtifactPowerPicker',
  'ArtifactPowerRank',
  'ArtifactQuestXP',
  'ArtifactTier',
  'ArtifactUnlock',
  'AttackAnimKits',
  'AttackAnimTypes',
  'AuctionHouse',
  'AuctionHouseCategory',
  'AzeriteEmpoweredItem',
  'AzeriteEssence',
  'AzeriteEssencePower',
  'AzeriteItem',
  'AzeriteItemMilestonePower',
  'AzeriteKnowledgeMultiplier',
  'AzeriteLevelInfo',
  'AzeritePower',
  'AzeritePowerSetMember',
  'AzeriteTierUnlock',
  'AzeriteTierUnlockSet',
  'AzeriteUnlockMapping',
  'BankBagSlotPrices',
  'BankTab',
  'BannedAddons',
  'BarberShopStyle',
  'BarrageEffect',
  'BattlemasterList',
  'BattlemasterListXMap',
  'BattlepayCurrency',
  'BattlePetAbility',
  'BattlePetAbilityEffect',
  'BattlePetAbilityState',
  'BattlePetAbilityTurn',
  'BattlePetBreedQuality',
  'BattlePetBreedState',
  'BattlePetDisplayOverride',
  'BattlePetEffectProperties',
  'BattlePetNPCTeamMember',
  'BattlePetSpecies',
  'BattlePetSpeciesState',
  'BattlePetSpeciesXAbility',
  'BattlePetSpeciesXCovenant',
  'BattlePetState',
  'BattlePetVisual',
  'BeamEffect',
  'BeckonTrigger',
  'BoneWindModifierModel',
  'BoneWindModifiers',
  'BonusRoll',
  'Bounty',
  'BountySet',
  'BroadcastText',
  'BroadcastTextDuration',
  'BroadcastTextSoundState',
  'BroadcastTextVOState',
  'CameraEffect',
  'CameraEffectEntry',
  'CameraMode',
  'CameraShakes',
  'Campaign',
  'CampaignXCondition',
  'CampaignXQuestLine',
  'CastableRaidBuffs',
  'CelestialBody',
  'Cfg_Categories',
  'Cfg_Configs',
  'Cfg_Languages',
  'Cfg_Realms',
  'Cfg_Regions',
  'ChallengeModeItemBonusOverride',
  'CharacterCreateCameras',
  'CharacterFaceBoneSet',
  'CharacterFacialHairStyles',
  'CharacterLoadout',
  'CharacterLoadoutItem',
  'CharacterLoadoutPet',
  'CharacterServiceInfo',
  'CharBaseInfo',
  'CharBaseSection',
  'CharComponentTextureLayouts',
  'CharComponentTextureSections',
  'CharHairGeosets',
  'CharHairTextures',
  'CharSectionCondition',
  'CharSections',
  'CharShipment',
  'CharShipmentContainer',
  'CharStartKit',
  'CharStartOutfit',
  'CharTextureVariationsV2',
  'CharTitles',
  'CharVariations',
  'ChatChannels',
  'ChatProfanity',
  'ChrClasses',
  'ChrClassesXPowerTypes',
  'ChrClassRaceSex',
  'ChrClassTitle',
  'ChrClassUIChrModelInfo',
  'ChrClassUIDisplay',
  'ChrClassVillain',
  'ChrCreateClassAnimTarget',
  'ChrCreateClassAnimTargetInfo',
  'ChrCustClientChoiceConversion',
  'ChrCustGeoComponentLink',
  'ChrCustItemGeoModify',
  'ChrCustomization',
  'ChrCustomizationBoneSet',
  'ChrCustomizationCategory',
  'ChrCustomizationChoice',
  'ChrCustomizationCondModel',
  'ChrCustomizationConversion',
  'ChrCustomizationDisplayInfo',
  'ChrCustomizationElement',
  'ChrCustomizationGeoset',
  'ChrCustomizationGlyphPet',
  'ChrCustomizationMaterial',
  'ChrCustomizationOption',
  'ChrCustomizationReq',
  'ChrCustomizationReqChoice',
  'ChrCustomizationSkinnedModel',
  'ChrCustomizationVisReq',
  'ChrCustomizationVoice',
  'ChrModel',
  'ChrModelMaterial',
  'ChrModelTextureLayer',
  'ChrModelTextureTarget',
  'ChrProficiency',
  'ChrRaceRacialAbility',
  'ChrRaces',
  'ChrRacesCreateScreenIcon',
  'ChrRacesPing',
  'ChrRaceXChrModel',
  'ChrSelectBackgroundCDI',
  'ChrSpecialization',
  'ChrUpgradeBucket',
  'ChrUpgradeBucketSpell',
  'ChrUpgradeTier',
  'Cinematic',
  'CinematicCamera',
  'CinematicSequences',
  'CinematicSubtitle',
  'ClientSceneEffect',
  'ClientSettings',
  'CloakDampening',
  'CloneEffect',
  'CollectableSourceEncounter',
  'CollectableSourceEncounterSparse',
  'CollectableSourceInfo',
  'CollectableSourceQuest',
  'CollectableSourceQuestSparse',
  'CollectableSourceVendor',
  'CollectableSourceVendorSparse',
  'ColorBanding',
  'CombatCondition',
  'CommentatorIndirectSpell',
  'CommentatorStartLocation',
  'CommentatorTrackedCooldown',
  'CommunityIcon',
  'ComponentModelFileData',
  'ComponentTextureFileData',
  'ConditionalChrModel',
  'ConditionalContentTuning',
  'ConditionalCreatureModelData',
  'ConditionalItemAppearance',
  'ConfigurationWarning',
  'ConsoleScripts',
  'ContentPush',
  'ContentRestrictionRule',
  'ContentRestrictionRuleSet',
  'ContentTuning',
  'ContentTuningDescription',
  'ContentTuningXExpected',
  'ContentTuningXExpectedStatMod',
  'ContentTuningXLabel',
  'Contribution',
  'ContributionStyle',
  'ContributionStyleContainer',
  'ConversationLine',
  'CorruptionEffects',
  'Covenant',
  'CraftingData',
  'CraftingDataEnchantQuality',
  'CraftingDataItemQuality',
  'CraftingDifficulty',
  'CraftingDifficultyQuality',
  'CraftingItem',
  'CraftingItemQuality',
  'CraftingOrder',
  'CraftingOrderHouse',
  'CraftingOrderXLabel',
  'CraftingQuality',
  'CraftingReagentEffect',
  'CraftingReagentQuality',
  'CraftingReagentRequirement',
  'Creature',
  'CreatureDifficulty',
  'CreatureDifficultyTreasure',
  'CreatureDisplayInfo',
  'CreatureDisplayInfoCond',
  'CreatureDisplayInfoCondXChoice',
  'CreatureDisplayInfoEvt',
  'CreatureDisplayInfoExtra',
  'CreatureDisplayInfoGeosetData',
  'CreatureDisplayInfoOption',
  'CreatureDisplayInfoTrn',
  'CreatureDisplayXUIModelScene',
  'CreatureDispXUiCamera',
  'CreatureFamily',
  'CreatureFamilyXUIModelScene',
  'CreatureImmunities',
  'CreatureLabel',
  'CreatureModelData',
  'CreatureMovementInfo',
  'CreatureSoundData',
  'CreatureSoundFidget',
  'CreatureSpellData',
  'CreatureType',
  'CreatureXContribution',
  'CreatureXDisplayInfo',
  'CreatureXUiWidgetSet',
  'Criteria',
  'CriteriaTree',
  'CriteriaTreeXEffect',
  'CurrencyCategory',
  'CurrencyContainer',
  'CurrencySource',
  'CurrencyTypes',
  'Curve',
  'CurvePoint',
  'DanceMoves',
  'DeathThudLookups',
  'DecalProperties',
  'DeclinedWord',
  'DeclinedWordCases',
  'DelvesSeason',
  'DelvesSeasonXSpell',
  'DestructibleModelData',
  'DeviceBlacklist',
  'DeviceDefaultSettings',
  'Difficulty',
  'DisplaySeason',
  'DissolveEffect',
  'DriverBlacklist',
  'DungeonEncounter',
  'DungeonMap',
  'DungeonMapChunk',
  'DurabilityCosts',
  'DurabilityQuality',
  'EdgeGlowEffect',
  'EmoteAnims',
  'Emotes',
  'EmotesText',
  'EmotesTextData',
  'EmotesTextSound',
  'EnumeratedString',
  'EnvironmentalDamage',
  'Exhaustion',
  'ExpectedStat',
  'ExpectedStatMod',
  'ExtraAbilityInfo',
  'Faction',
  'FactionGroup',
  'FactionTemplate',
  'FileData',
  'FileDataComplete',
  'FilePaths',
  'FlightCapability',
  'FlightCapabilityXGlideEvent',
  'FootprintTextures',
  'FootstepTerrainLookup',
  'FriendshipRepReaction',
  'FriendshipReputation',
  'FullScreenEffect',
  'GameClockDebug',
  'GameMode',
  'GameObjectAnimGroupMember',
  'GameObjectArtKit',
  'GameObjectDiffAnimMap',
  'GameObjectDisplayCondition',
  'GameObjectDisplayInfo',
  'GameObjectDisplayInfoXSoundKit',
  'GameObjectLabel',
  'GameObjects',
  'GameObjectsClient',
  'GameParameter',
  'GameTables',
  'GameTips',
  'GarrAbility',
  'GarrAbilityCategory',
  'GarrAbilityEffect',
  'GarrAutoCombatant',
  'GarrAutoSpell',
  'GarrAutoSpellEffect',
  'GarrBuilding',
  'GarrBuildingDoodadSet',
  'GarrBuildingPlotInst',
  'GarrClassSpec',
  'GarrClassSpecPlayerCond',
  'GarrEncounter',
  'GarrEncounterSetXEncounter',
  'GarrEncounterXMechanic',
  'GarrFamilyName',
  'GarrFollItemSet',
  'GarrFollItemSetMember',
  'GarrFollower',
  'GarrFollowerLevelXP',
  'GarrFollowerQuality',
  'GarrFollowerSetXFollower',
  'GarrFollowerType',
  'GarrFollowerUICreature',
  'GarrFollowerXAbility',
  'GarrFollSupportSpell',
  'GarrGivenName',
  'GarrItemLevelUpgradeData',
  'GarrMechanic',
  'GarrMechanicSetXMechanic',
  'GarrMechanicType',
  'GarrMission',
  'GarrMissionReward',
  'GarrMissionSet',
  'GarrMissionTexture',
  'GarrMissionType',
  'GarrMissionXEncounter',
  'GarrMissionXFollower',
  'GarrMssnBonusAbility',
  'GarrPlot',
  'GarrPlotBuilding',
  'GarrPlotInstance',
  'GarrPlotUICategory',
  'GarrSiteLevel',
  'GarrSiteLevelPlotInst',
  'GarrSpecialization',
  'GarrString',
  'GarrTalent',
  'GarrTalentCost',
  'GarrTalentMapPOI',
  'GarrTalentRank',
  'GarrTalentRankGroupEntry',
  'GarrTalentRankGroupResearchMod',
  'GarrTalentResearch',
  'GarrTalentSocketProperties',
  'GarrTalentTree',
  'GarrTalTreeXGarrTalResearch',
  'GarrType',
  'GarrUiAnimClassInfo',
  'GarrUiAnimRaceInfo',
  'GemProperties',
  'GlideEvent',
  'GlideEventBlendTimes',
  'GlobalColor',
  'GlobalCurve',
  'GlobalGameContentTuning',
  'GlobalPlayerCondition',
  'GlobalPlayerConditionSet',
  'GlobalStrings',
  'GlobalTable_PlayerCondition',
  'GlueScreenEmote',
  'GlyphBindableSpell',
  'GlyphExclusiveCategory',
  'GlyphProperties',
  'GlyphRequiredSpec',
  'GlyphSlot',
  'GMSurveyAnswers',
  'GMSurveyCurrentSurvey',
  'GMSurveyQuestions',
  'GMSurveySurveys',
  'GMTicketCategory',
  'GossipNPCOption',
  'GossipNPCOptionDisplayInfo',
  'GossipOptionXUIWidgetSet',
  'GossipUIDisplayInfoCondition',
  'GossipXGarrTalentTrees',
  'GossipXUIDisplayInfo',
  'GradientEffect',
  'GroundEffectDoodad',
  'GroundEffectTexture',
  'GroupFinderActivity',
  'GroupFinderActivityGrp',
  'GroupFinderActivityXPvpBracket',
  'GroupFinderCategory',
  'gtArmorMitigationByLvl',
  'gtBarberShopCostBase',
  'gtBattlePetTypeDamageMod',
  'gtBattlePetXP',
  'gtChanceToMeleeCrit',
  'gtChanceToMeleeCritBase',
  'gtChanceToSpellCrit',
  'gtChanceToSpellCritBase',
  'gtCombatRatings',
  'gtItemSocketCostPerLevel',
  'gtMasteryMultipliers',
  'gtNPCManaCostScaler',
  'gtOCTBaseHPByClass',
  'gtOCTBaseMPByClass',
  'gtOCTClassCombatRatingScalar',
  'gtOCTHpPerStamina',
  'gtOCTLevelExperience',
  'gtOCTRegenHP',
  'gtOCTRegenMP',
  'gtRegenHPPerSpt',
  'gtRegenMPPerSpt',
  'gtResilienceDR',
  'gtSpellScaling',
  'GuildColorBackground',
  'GuildColorBorder',
  'GuildColorEmblem',
  'GuildEmblem',
  'GuildPerkSpells',
  'GuildShirtBackground',
  'GuildShirtBorder',
  'GuildTabardBackground',
  'GuildTabardBorder',
  'GuildTabardEmblem',
  'Heirloom',
  'HelmetAnimScaling',
  'HelmetGeosetData',
  'HelmetGeosetVisData',
  'HighlightColor',
  'HolidayDescriptions',
  'HolidayNames',
  'Holidays',
  'Hotfix',
  'Hotfixes',
  'ImportPriceArmor',
  'ImportPriceQuality',
  'ImportPriceShield',
  'ImportPriceWeapon',
  'InvasionClientData',
  'Item',
  'Item-sparse',
  'ItemAppearance',
  'ItemAppearanceXUiCamera',
  'ItemArmorQuality',
  'ItemArmorShield',
  'ItemArmorTotal',
  'ItemBagFamily',
  'ItemBonus',
  'ItemBonusList',
  'ItemBonusListGroup',
  'ItemBonusListGroupEntry',
  'ItemBonusListLevelDelta',
  'ItemBonusListWarforgeLevelDelta',
  'ItemBonusSeason',
  'ItemBonusSeasonBonusListGroup',
  'ItemBonusSeasonUpgradeCost',
  'ItemBonusSequenceSpell',
  'ItemBonusTree',
  'ItemBonusTreeGroupEntry',
  'ItemBonusTreeNode',
  'ItemChildEquipment',
  'ItemClass',
  'ItemCondExtCosts',
  'ItemCondition',
  'ItemContextPickerEntry',
  'ItemConversion',
  'ItemConversionEntry',
  'ItemCurrencyCost',
  'ItemCurrencyValue',
  'ItemDamageAmmo',
  'ItemDamageOneHand',
  'ItemDamageOneHandCaster',
  'ItemDamageRanged',
  'ItemDamageThrown',
  'ItemDamageTwoHand',
  'ItemDamageTwoHandCaster',
  'ItemDamageWand',
  'ItemDisenchantLoot',
  'ItemDisplayInfo',
  'ItemDisplayInfoMaterialRes',
  'ItemDisplayInfoModelMatRes',
  'ItemDisplayXUiCamera',
  'ItemEffect',
  'ItemExtendedCost',
  'ItemFallbackVisual',
  'ItemFixup',
  'ItemFixupAction',
  'ItemGroupIlvlScalingEntry',
  'ItemGroupSounds',
  'ItemLevelSelector',
  'ItemLevelSelectorQuality',
  'ItemLevelSelectorQualitySet',
  'ItemLimitCategory',
  'ItemLimitCategoryCondition',
  'ItemLogicalCost',
  'ItemLogicalCostGroup',
  'ItemModifiedAppearance',
  'ItemModifiedAppearanceExtra',
  'ItemNameDescription',
  'ItemNameSlotOverride',
  'ItemPetFood',
  'ItemPriceBase',
  'ItemPurchaseGroup',
  'ItemRandomProperties',
  'ItemRandomSuffix',
  'ItemRangedDisplayInfo',
  'ItemRecraft',
  'ItemReforge',
  'ItemSalvage',
  'ItemSalvageLoot',
  'ItemSearchName',
  'ItemSet',
  'ItemSetSpell',
  'ItemSparse',
  'ItemSpec',
  'ItemSpecOverride',
  'ItemSubClass',
  'ItemSubClassMask',
  'ItemToBattlePet',
  'ItemToMountSpell',
  'ItemUpgrade',
  'ItemUpgradePath',
  'ItemVisualEffects',
  'ItemVisuals',
  'ItemVisualsXEffect',
  'ItemXBonusTree',
  'ItemXItemEffect',
  'JournalEncounter',
  'JournalEncounterCreature',
  'JournalEncounterItem',
  'JournalEncounterSection',
  'JournalEncounterXDifficulty',
  'JournalEncounterXMapLoc',
  'JournalInstance',
  'JournalInstanceEntrance',
  'JournalInstanceQueueLoc',
  'JournalItemXDifficulty',
  'JournalSectionXDifficulty',
  'JournalTier',
  'JournalTierXInstance',
  'Keychain',
  'KeystoneAffix',
  'LabelXContentRestrictRuleSet',
  'Languages',
  'LanguageWords',
  'LFGDungeonExpansion',
  'LFGDungeonGroup',
  'LFGDungeons',
  'LfgDungeonsGroupingMap',
  'LFGRoleRequirement',
  'Light',
  'LightData',
  'LightFloatBand',
  'LightIntBand',
  'Lightning',
  'LightParams',
  'LightParamsLightShaft',
  'LightShaft',
  'LightSkybox',
  'LightWorldShadow',
  'LiquidMaterial',
  'LiquidObject',
  'LiquidType',
  'LiquidTypeXTexture',
  'LivingWorldObjectTemplate',
  'LivingWorldObjectTemplateModel',
  'LoadingScreens',
  'LoadingScreenSkin',
  'LoadingScreenTaxiSplines',
  'Locale',
  'Location',
  'Lock',
  'LockType',
  'LookAtController',
  'LoreText',
  'LoreTextPublic',
  'MailTemplate',
  'ManagedWorldState',
  'ManagedWorldStateBuff',
  'ManagedWorldStateInput',
  'ManifestInterfaceActionIcon',
  'ManifestInterfaceData',
  'ManifestInterfaceItemIcon',
  'ManifestInterfaceTOCData',
  'ManifestMP3',
  'Map',
  'MapCelestialBody',
  'MapChallengeMode',
  'MapDifficulty',
  'MapDifficultyRedirect',
  'MapDifficultyXCondition',
  'MapLoadingScreen',
  'MapRenderScale',
  'MarketingPromotionsXLocale',
  'Material',
  'MawPower',
  'MawPowerRarity',
  'MCRSlotXMCRCategory',
  'MinorTalent',
  'MissileTargeting',
  'MobileStrings',
  'ModelAnimCloakDampening',
  'ModelFileData',
  'ModelManifest',
  'ModelNameToManifest',
  'ModelRibbonQuality',
  'ModelSound',
  'ModelSoundAnimEntry',
  'ModelSoundEntry',
  'ModelSoundOverride',
  'ModelSoundOverrideName',
  'ModelSoundSettings',
  'ModelSoundTagEntry',
  'ModifiedCraftingCategory',
  'ModifiedCraftingItem',
  'ModifiedCraftingReagentItem',
  'ModifiedCraftingReagentSlot',
  'ModifiedCraftingSpellSlot',
  'ModifiedReagentItem',
  'ModifierTree',
  'Mount',
  'MountCapability',
  'MountEquipment',
  'MountType',
  'MountTypeXCapability',
  'MountXDisplay',
  'MountXSpellVisualKitPicker',
  'Movie',
  'MovieFileData',
  'MovieOverlays',
  'MovieVariation',
  'MultiStateProperties',
  'MultiTransitionProperties',
  'MusicOverride',
  'MythicPlusSeason',
  'MythicPlusSeasonRewardLevels',
  'MythicPlusSeasonTrackedAffix',
  'MythicPlusSeasonTrackedMap',
  'NameGen',
  'NamesProfanity',
  'NamesReserved',
  'NamesReservedLocale',
  'NPCCraftingOrderCustomer',
  'NPCCraftingOrderCustomerXLabel',
  'NPCCraftingOrderSet',
  'NPCCraftingOrderSetXCraftOrder',
  'NPCCraftingOrderSetXCustomer',
  'NPCCraftingOrderSetXTreasure',
  'NPCModelItemSlotDisplayInfo',
  'NPCSounds',
  'NumTalentsAtLevel',
  'ObjectEffect',
  'ObjectEffectGroup',
  'ObjectEffectModifier',
  'ObjectEffectPackage',
  'ObjectEffectPackageElem',
  'ObjectEffectStateName',
  'Occluder',
  'OccluderCurtain',
  'OccluderLocation',
  'OccluderNode',
  'OutlineEffect',
  'OverrideSpellData',
  'Package',
  'PageTextMaterial',
  'PaperDollItemFrame',
  'ParagonReputation',
  'ParticleColor',
  'Particulate',
  'ParticulateSound',
  'Path',
  'PathEdge',
  'PathNode',
  'PathNodeProperty',
  'PathProperty',
  'PerksActivity',
  'PerksActivityCondition',
  'PerksActivityTag',
  'PerksActivityThreshold',
  'PerksActivityThresholdGroup',
  'PerksActivityXHolidays',
  'PerksActivityXInterval',
  'PerksActivityXTag',
  'PerksUITheme',
  'PerksVendorCategory',
  'PerksVendorItem',
  'PerksVendorItemUIGroup',
  'PerksVendorItemUIInfo',
  'PerksVendorItemXInterval',
  'PetitionType',
  'PetLoyalty',
  'PetPersonality',
  'Phase',
  'PhaseShiftZoneSounds',
  'PhaseXPhaseGroup',
  'PingType',
  'PlayerCompanionInfo',
  'PlayerCondition',
  'PlayerDataElementAccount',
  'PlayerDataElementCharacter',
  'PlayerDataFlagAccount',
  'PlayerDataFlagCharacter',
  'PlayerInteractionInfo',
  'PointLightConditionMap',
  'Positioner',
  'PositionerState',
  'PositionerStateEntry',
  'PowerDisplay',
  'PowerType',
  'PrestigeLevelInfo',
  'Profession',
  'ProfessionEffect',
  'ProfessionEffectType',
  'ProfessionExpansion',
  'ProfessionPropPoints',
  'ProfessionRating',
  'ProfessionTrait',
  'ProfessionTraitXEffect',
  'ProfessionTraitXLabel',
  'ProfessionXRating',
  'ProfTraitPathNode',
  'ProfTraitPerkNode',
  'ProfTraitTree',
  'ProfTraitTreeHighlight',
  'PVPBracketTypes',
  'PvpBrawl',
  'PVPDifficulty',
  'PVPItem',
  'PvpRating',
  'PvpReward',
  'PvpScalingEffect',
  'PvpScalingEffectType',
  'PVPScoreboardCellInfo',
  'PVPScoreboardColumnHeader',
  'PVPScoreboardLayout',
  'PvpSeason',
  'PvpSeasonRewardLevels',
  'PVPStat',
  'PvpTalent',
  'PvpTalentCategory',
  'PvpTalentSlotUnlock',
  'PvpTalentUnlock',
  'PvpTier',
  'QuestDrivenScenario',
  'QuestFactionReward',
  'QuestFeedbackEffect',
  'QuestHub',
  'QuestInfo',
  'QuestLabel',
  'QuestLine',
  'QuestLineXQuest',
  'QuestMoneyReward',
  'QuestObjective',
  'QuestPackageItem',
  'QuestPOIBlob',
  'QuestPOIPoint',
  'QuestSort',
  'QuestV2',
  'QuestV2CliTask',
  'QuestXGroupActivity',
  'QuestXP',
  'QuestXUIQuestDetailsTheme',
  'QuestXUiWidgetSet',
  'RacialMounts',
  'RafActivity',
  'RandPropPoints',
  'RecipeProgressionGroupEntry',
  'RelicSlotTierRequirement',
  'RelicTalent',
  'RenownRewards',
  'RenownRewardsPlunderstorm',
  'ResearchBranch',
  'ResearchField',
  'ResearchProject',
  'ResearchSite',
  'Resistances',
  'RewardPack',
  'RewardPackXCurrencyType',
  'RewardPackXItem',
  'RibbonQuality',
  'RopeEffect',
  'RTPC',
  'RTPCData',
  'RulesetItemUpgrade',
  'RulesetRaidLootUpgrade',
  'RulesetRaidOverride',
  'RuneforgeLegendaryAbility',
  'SandboxScaling',
  'ScalingStatDistribution',
  'ScalingStatValues',
  'Scenario',
  'ScenarioEventEntry',
  'ScenarioStep',
  'SceneScript',
  'SceneScriptGlobalText',
  'SceneScriptPackage',
  'SceneScriptPackageMember',
  'SceneScriptText',
  'ScheduledInterval',
  'ScheduledWorldState',
  'ScheduledWorldStateGroup',
  'ScheduledWorldStateXUniqCat',
  'ScreenEffect',
  'ScreenEffectType',
  'ScreenLocation',
  'SDReplacementModel',
  'SeamlessSite',
  'ServerMessages',
  'ShadowyEffect',
  'SharedString',
  'SheatheSoundLookups',
  'SiegeableProperties',
  'SkillCostsData',
  'SkillLine',
  'SkillLineAbility',
  'SkillLineAbilitySortedSpell',
  'SkillLineCategory',
  'SkillLineXTraitTree',
  'SkillRaceClassInfo',
  'SkillTiers',
  'SkySceneXPlayerCondition',
  'Soulbind',
  'SoulbindConduit',
  'SoulbindConduitEnhancedSocket',
  'SoulbindConduitItem',
  'SoulbindConduitRank',
  'SoulbindConduitRankProperties',
  'SoulbindUIDisplayInfo',
  'SoundAmbience',
  'SoundAmbienceFlavor',
  'SoundBus',
  'SoundBusName',
  'SoundBusOverride',
  'SoundCharacterMacroLines',
  'SoundEmitterPillPoints',
  'SoundEmitters',
  'SoundEntries',
  'SoundEntriesAdvanced',
  'SoundEntriesFallbacks',
  'SoundEnvelope',
  'SoundFilter',
  'SoundFilterElem',
  'SoundKit',
  'SoundKitAdvanced',
  'SoundKitChild',
  'SoundKitEntry',
  'SoundKitFallback',
  'SoundKitName',
  'SoundMixGroup',
  'SoundOverride',
  'SoundParameter',
  'SoundProviderPreferences',
  'SoundSamplePreferences',
  'SoundWaterfallEmitter',
  'SoundWaterType',
  'SourceInfo',
  'SpamMessages',
  'SpecializationSpells',
  'SpecializationSpellsDisplay',
  'SpecSetMember',
  'Spell',
  'SpellActionBarPref',
  'SpellActivationOverlay',
  'SpellAuraNames',
  'SpellAuraOptions',
  'SpellAuraRestrictions',
  'SpellAuraRestrictionsDifficulty',
  'SpellAuraVisibility',
  'SpellAuraVisXChrSpec',
  'SpellAuraVisXTalentTab',
  'SpellCastingRequirements',
  'SpellCastTimes',
  'SpellCategories',
  'SpellCategory',
  'SpellChainEffects',
  'SpellClassOptions',
  'SpellClutterAreaEffectCounts',
  'SpellClutterFrameRates',
  'SpellClutterImpactModelCounts',
  'SpellClutterKitDistances',
  'SpellClutterMissileDist',
  'SpellClutterWeaponTrailDist',
  'SpellCooldowns',
  'SpellCraftUI',
  'SpellDescriptionVariables',
  'SpellDifficulty',
  'SpellDispelType',
  'SpellDuration',
  'SpellEffect',
  'SpellEffectAutoDescription',
  'SpellEffectCameraShakes',
  'SpellEffectEmission',
  'SpellEffectGroupSize',
  'SpellEffectNames',
  'SpellEffectScaling',
  'SpellEmpower',
  'SpellEmpowerStage',
  'SpellEquippedItems',
  'SpellFlyout',
  'SpellFlyoutItem',
  'SpellFocusObject',
  'SpellIcon',
  'SpellInterrupts',
  'SpellItemEnchantment',
  'SpellItemEnchantmentCondition',
  'SpellKeyboundOverride',
  'SpellLabel',
  'SpellLearnSpell',
  'SpellLevels',
  'SpellMastery',
  'SpellMechanic',
  'SpellMemorizeCost',
  'SpellMisc',
  'SpellMiscDifficulty',
  'SpellMissile',
  'SpellMissileMotion',
  'SpellName',
  'SpellOverrideName',
  'SpellPower',
  'SpellPowerDifficulty',
  'SpellProceduralEffect',
  'SpellProcsPerMinute',
  'SpellProcsPerMinuteMod',
  'SpellRadius',
  'SpellRange',
  'SpellReagents',
  'SpellReagentsCurrency',
  'SpellReplacement',
  'SpellRuneCost',
  'SpellScaling',
  'SpellScript',
  'SpellScriptText',
  'SpellShapeshift',
  'SpellShapeshiftForm',
  'SpellSpecialUnitEffect',
  'SpellTargetRestrictions',
  'SpellTooltip',
  'SpellTotems',
  'SpellVisual',
  'SpellVisualAnim',
  'SpellVisualAnimName',
  'SpellVisualColorEffect',
  'SpellVisualEffectName',
  'SpellVisualEvent',
  'SpellVisualKit',
  'SpellVisualKitAreaModel',
  'SpellVisualKitEffect',
  'SpellVisualKitModelAttach',
  'SpellVisualKitPicker',
  'SpellVisualKitPickerEntry',
  'SpellVisualMissile',
  'SpellVisualPrecastTransitions',
  'SpellVisualScreenEffect',
  'SpellXDescriptionVariables',
  'SpellXSpellVisual',
  'SpotLightConditionMap',
  'SSAOSettings',
  'StableSlotPrices',
  'Startup_Strings',
  'StartupFiles',
  'Stationery',
  'StringLookups',
  'SummonProperties',
  'TabardBackgroundTextures',
  'TabardEmblemTextures',
  'TactKey',
  'TactKeyLookup',
  'Talent',
  'TalentTab',
  'TalentTreePrimarySpells',
  'TaxiNodes',
  'TaxiPath',
  'TaxiPathNode',
  'TeamContributionPoints',
  'TerrainColorGradingRamp',
  'TerrainMaterial',
  'TerrainType',
  'TerrainTypeSounds',
  'TextureBlendSet',
  'TextureFileData',
  'TierTransition',
  'TotemCategory',
  'Toy',
  'TradeSkillCategory',
  'TradeSkillItem',
  'TraitCond',
  'TraitCondAccountElement',
  'TraitCost',
  'TraitCostDefinition',
  'TraitCurrency',
  'TraitCurrencySource',
  'TraitDefinition',
  'TraitDefinitionEffectPoints',
  'TraitEdge',
  'TraitNode',
  'TraitNodeEntry',
  'TraitNodeEntryXTraitCond',
  'TraitNodeEntryXTraitCost',
  'TraitNodeGroup',
  'TraitNodeGroupXTraitCond',
  'TraitNodeGroupXTraitCost',
  'TraitNodeGroupXTraitNode',
  'TraitNodeXTraitCond',
  'TraitNodeXTraitCost',
  'TraitNodeXTraitNodeEntry',
  'TraitSubTree',
  'TraitSystem',
  'TraitTree',
  'TraitTreeLoadout',
  'TraitTreeLoadoutEntry',
  'TraitTreeXTraitCost',
  'TraitTreeXTraitCurrency',
  'TransformMatrix',
  'TransmogDefaultLevel',
  'TransmogHoliday',
  'TransmogIllusion',
  'TransmogSet',
  'TransmogSetGroup',
  'TransmogSetItem',
  'TransportAnimation',
  'TransportPhysics',
  'TransportRotation',
  'Treasure',
  'Trophy',
  'TrophyInstance',
  'TrophyType',
  'UIArrowCallout',
  'UIButton',
  'UiCamera',
  'UiCameraType',
  'UiCamFbackTalkingHeadChrRace',
  'UiCamFbackTransmogChrRace',
  'UiCamFbackTransmogWeapon',
  'UiCanvas',
  'UIChromieTimeExpansionInfo',
  'UICinematicIntroInfo',
  'UICovenantAbility',
  'UiCovenantDisplayInfo',
  'UICovenantPreview',
  'UIDeadlyDebuff',
  'UIDungeonScoreRarity',
  'UIEventToast',
  'UIExpansionDisplayInfo',
  'UIExpansionDisplayInfoIcon',
  'UIGenericWidgetDisplay',
  'UiItemInteraction',
  'UiMap',
  'UiMapArt',
  'UiMapArtStyleLayer',
  'UiMapArtTile',
  'UiMapAssignment',
  'UiMapFogOfWar',
  'UiMapFogOfWarVisualization',
  'UiMapGroup',
  'UiMapGroupMember',
  'UiMapLink',
  'UIMapPinInfo',
  'UiMapPOI',
  'UiMapXMapArt',
  'UiModelScene',
  'UiModelSceneActor',
  'UiModelSceneActorDisplay',
  'UiModelSceneCamera',
  'UIModifiedInstance',
  'UiPartyPose',
  'UiQuestDetailsTheme',
  'UIScriptedAnimationEffect',
  'UISoundLookups',
  'UISplashScreen',
  'UiTextureAtlas',
  'UiTextureAtlasElement',
  'UiTextureAtlasElementOverride',
  'UiTextureAtlasElementSliceData',
  'UiTextureAtlasMember',
  'UiTextureKit',
  'UiWeeklyReward',
  'UiWidget',
  'UiWidgetConstantSource',
  'UiWidgetDataSource',
  'UiWidgetMap',
  'UiWidgetSet',
  'UiWidgetStringSource',
  'UiWidgetVisTypeDataReq',
  'UiWidgetVisualization',
  'UiWidgetXWidgetSet',
  'UnitBlood',
  'UnitBloodLevels',
  'UnitCondition',
  'UnitPowerBar',
  'UnitTest',
  'UnitTestSparse',
  'Vehicle',
  'VehiclePOIType',
  'VehicleSeat',
  'VehicleUIIndicator',
  'VehicleUIIndSeat',
  'VideoHardware',
  'Vignette',
  'VignetteUiWidgetSet',
  'VirtualAttachment',
  'VirtualAttachmentCustomization',
  'VocalUISounds',
  'VoiceOverPriority',
  'VolumeFogCondition',
  'VW_MobileSpell',
  'WarbandScene',
  'WarbandSceneAnimation',
  'WarbandScenePlacement',
  'WarbandScenePlcmntAnimOverride',
  'WaterfallData',
  'WaypointEdge',
  'WaypointNode',
  'WaypointSafeLocs',
  'WbAccessControlList',
  'WbCertBlacklist',
  'WbCertWhitelist',
  'WbPermissions',
  'WeaponImpactSounds',
  'WeaponSwingSounds2',
  'WeaponTrail',
  'WeaponTrailModelDef',
  'WeaponTrailParam',
  'Weather',
  'WeatherXParticulate',
  'WeeklyRewardChestActivityTier',
  'WeeklyRewardChestThreshold',
  'WindSettings',
  'WMOAreaTable',
  'WMOMinimapTexture',
  'World_PVP_Area',
  'WorldBossLockout',
  'WorldChunkSounds',
  'WorldEffect',
  'WorldElapsedTimer',
  'WorldLayerMapSet',
  'WorldMapArea',
  'WorldMapContinent',
  'WorldMapOverlay',
  'WorldMapOverlayTile',
  'WorldMapTransforms',
  'WorldSafeLocs',
  'WorldShadow',
  'WorldState',
  'WorldStateExpression',
  'WorldStateUI',
  'WorldStateZoneSounds',
  'WowError_Strings',
  'ZoneIntroMusicTable',
  'ZoneLight',
  'ZoneLightPoint',
  'ZoneMusic',
  'ZoneStory',
]

function SStrHash(string: string): number {
  function normalizeChar(c: string): string {
    return c === '/' ? '\\' : c.toUpperCase()
  }

  const hashTable = [
    0x486e26ee, 0xdcaa16b3, 0xe1918eef, 0x202dafdb, 0x341c7dc7, 0x1c365303, 0x40ef2d37, 0x65fd5e49, 0xd6057177,
    0x904ece93, 0x1c38024f, 0x98fd323b, 0xe3061ae7, 0xa39b0fa1, 0x9797f25f, 0xe4444563,
  ]

  let seed = 0x7fed7fed
  let shift = 0xeeeeeeee >>> 0

  for (let i = 0; i < string.length; i++) {
    const c = normalizeChar(string[i]).charCodeAt(0)

    // Apply 32-bit masking for unsigned integer behavior
    seed = ((hashTable[c >> 4] - hashTable[c & 0xf]) >>> 0) ^ ((shift + seed) >>> 0)
    shift = (c + seed + 33 * shift + 3) >>> 0
  }

  return seed >>> 0 || 1
}

const DB_TABLE_HASH_LOOKUP: Record<number, string> = []
for (const name of DB_NAMES) {
  const hash = SStrHash(name)
  DB_TABLE_HASH_LOOKUP[hash] = name
}

export function getDBTableName(): Record<number, string> {
  return DB_TABLE_HASH_LOOKUP
}
