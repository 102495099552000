import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Login.css'
import axios from 'axios'

async function loginUser({
  username,
  password,
  setError,
}: {
  username: string
  password: string
  setError: React.Dispatch<React.SetStateAction<string>>
}) {
  const authEndpoint = 'https://services.echoesports.gg:2096/gatekeeper'
  try {
    const { data } = await axios.post(authEndpoint, { username, password })
    if (data.success === true) {
      return data.token
    }
    setError('Invalid username or password')
    return
  } catch (error) {
    if (error) {
      setError('Login failed. Please try again later.')
    }
  }
}

export default function Login({ setToken }: { setToken: (token: string) => void }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const token = await loginUser({
      username,
      password,
      setError,
    })
    setToken(token || '')
    setPassword('')
  }

  return (
    <div className="login-wrapper">
      <img src="/echoLogo.svg" alt="Echo Logo" />
      <h1>Password Required</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <input type="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Submit</button>
        </label>
      </form>
      {error && <div id="login_error">{error}</div>}
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
}
