import React, { useState, useEffect, useContext } from 'react'
import { Menu, MenuItem, MenuButton, MenuRadioGroup, FocusableItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/theme-dark.css'
import { InstanceContext, ExpansionContext } from '../../../contexts/Context'
import { useParams } from 'react-router-dom'

function InstanceDropdown({ instanceType }: { instanceType: any }) {
  const { buildguid } = useParams()
  const [activeExpansion] = useContext(ExpansionContext)
  const [activeInstance, setActiveInstance] = useContext(InstanceContext)
  const [instances, setInstances] = useState<any[]>([])
  const [instanceFilter, setInstanceFilter] = useState<string>('')

  let storedActiveInstanceName = ''
  const item = localStorage.getItem('activeInstanceName')
  if (item) {
    try {
      storedActiveInstanceName = JSON.parse(item)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  const [activeInstanceName, setActiveInstanceName] = useState(storedActiveInstanceName)
  useEffect(() => {
    const instanceName = instances.find((instance) => instance.id === activeInstance)
    if (instanceName) {
      setActiveInstanceName(instanceName.name_lang)
      localStorage.setItem('activeInstanceName', JSON.stringify(instanceName.name_lang))
    }
  }, [activeInstance, instances])

  useEffect(() => {
    if (!buildguid || !activeExpansion) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + buildguid + '/expansion/' + activeExpansion)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        // Sort dungeons by id and filter out any that do not have the right type
        data = data.filter((instance: any) => instance.instanceMap.instancetype === instanceType)
        data.sort((a: any, b: any) => a.id - b.id)
        setInstances(data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [buildguid, activeExpansion, instanceType, activeInstance])

  return (
    <Menu
      menuButton={<MenuButton>{activeInstanceName}</MenuButton>}
      onMenuChange={(e) => e.open && setInstanceFilter('')}
      arrow={true}
      theming={'dark'}
    >
      <FocusableItem>
        {({ ref }) => (
          <input
            ref={ref}
            type="text"
            placeholder="Type to filter"
            value={instanceFilter}
            onChange={(e) => setInstanceFilter(e.target.value)}
          />
        )}
      </FocusableItem>
      <MenuRadioGroup value={activeInstance} onRadioChange={(e) => setActiveInstance(e.value)}>
        {instances
          .filter((dungeon) => dungeon.name_lang.toUpperCase().includes(instanceFilter.trim().toUpperCase()))
          .map((dungeon) => (
            <MenuItem type="radio" key={dungeon.id} value={dungeon.id}>
              {dungeon.name_lang}
            </MenuItem>
          ))}
      </MenuRadioGroup>
    </Menu>
  )
}

export default InstanceDropdown
