import { GetClassInfo } from './../ClassInfo'

const noteLines = [
  '#rocket1 |cff0070ddStoveflorist|r |cff00ff98Clickzmonk|r |cff00ff98Swagxdd|r |cffa330c9Nicememesdhc|r |cffa330c9Kushiwushi|r |cffc69b6dRevvezj|r |cffc69b6dWallifexd|r |cff8788eeXerwofour|r |cff33937fMaydatory|r |cffaad372Solhunti|r |cffffffffRycncaught|r |cffff7c0aHankstick|r |cff00ff98Velomunk|r |cff00ff98Zhatzimw|r |cff00ff98Artymeowing|r ',
  '#rocket2 |cffc69b6dMoores|r  |cfff48cbaTiefpalxdd|r |cfffff468Skìtzômåxîñg|r  |cff3fc7ebHopefulgg|r |cff3fc7ebGingipumper|r ',
  '#rocket3 |cff0070ddStoveflorist|r |cff00ff98Clickzmonk|r |cff00ff98Swagxdd|r |cffa330c9Nicememesdhc|r |cffa330c9Kushiwushi|r |cffc69b6dRevvezj|r |cffc69b6dWallifexd|r |cff8788eeXerwofour|r |cff33937fMaydatory|r |cffaad372Solhunti|r |cffffffffRycncaught|r |cffff7c0aHankstick|r |cff00ff98Velomunk|r |cff00ff98Zhatzimw|r |cff00ff98Artymeowing|r ',
  '#rocket4 |cffc69b6dMoores|r  |cfff48cbaTiefpalxdd|r |cff3fc7ebHopefulgg|r |cff3fc7ebGingipumper|r |cfffff468Skìtzômåxîñg|r ',
  '',
  '#goonprio {triangle}|cff3fc7ebGingipumper|r {square}|cff3fc7ebHopefulgg|r {circle}|cffaad372Solhunti|r {diamond}|cffa330c9Nicememesdhc|r ',
  '#goonsquad1 |cffa330c9Kushiwushi|r  |cff00ff98Swagxdd|r |cff00ff98Clickzmonk|r |cff8788eeXerwofour|r |cffa330c9Nicememesdhc|r ',
  '#goonsquad2 |cffa330c9Kushiwushi|r |cff00ff98Swagxdd|r |cff00ff98Clickzmonk|r |cff8788eeXerwofour|r |cffa330c9Nicememesdhc|r ',
  '#goonsquad3 |cff00ff98Swagxdd|r |cffc69b6dWallifexd|r |cffc69b6dRevvezj|r |cff00ff98Clickzmonk|r |cff8788eeXerwofour|r |cfffff468Skìtzômåxîñg|r |cffa330c9Kushiwushi|r |cffa330c9Nicememesdhc|r |cffaad372Solhunti|r ',
  '#badsquad1 |cff0070ddStoveflorist|r |cffaad372Solhunti|r |cfffff468Skìtzômåxîñg|r |cffc69b6dWallifexd|r |cffc69b6dRevvezj|r ',
  '#badsquad2 |cff0070ddStoveflorist|r |cffaad372Solhunti|r |cfffff468Skìtzômåxîñg|r |cffc69b6dWallifexd|r |cffc69b6dRevvezj|r ',
  '#badsquad3 |cff0070ddStoveflorist|r',
]

export function createMugZeeNote(playerInfo: PlayerInfo[]): Note {
  let note = ''
  const newNoteLines = [...noteLines]
  for (let i = 0; i < newNoteLines.length; i++) {
    // replace $player with player name and then remove from playerlist, this happens multiple times per line so we need to move along in the playerlist
    while (newNoteLines[i].indexOf('$player') !== -1 && playerInfo.length > 0) {
      const playerName = playerInfo[0].name
      const playerClassHex = GetClassInfo(playerInfo[0].classId).hexColor
      const playerNoteString = `|c${playerClassHex}${playerName}|r`
      newNoteLines[i] = newNoteLines[i].replace('$player', playerNoteString)
      playerInfo.splice(0, 1)
    }
    note += newNoteLines[i] + '\n'
  }
  return { boss: 'MugZee', note: note, lines: newNoteLines.length }
}
