import React, { useState } from 'react'
import useToken from '../../hooks/useToken'
import { Button, TextField, Paper, Grid2 as Grid, Stack, Box } from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { styled } from '@mui/material/styles'
import { GenerateNotes } from './NoteGeneration'
import { useSnackbar } from 'notistack'
// import { CopyToClipboard } from '../../utils/CopyToClipboard'

const Column = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const defaultPlayerListString = `Paste the character...`

const defaultNotes: Note[] = [
  {
    boss: 'No notes',
    note: `Genereate notes by clicking the button after adding a playerlist`,
    lines: 1,
  },
]

function Note() {
  const [playerList, setPlayerList] = useState<string>(defaultPlayerListString)
  const { enqueueSnackbar } = useSnackbar()
  const [notes, setNotes] = useState<Note[]>(defaultNotes)
  const { token } = useToken()

  // const handleNoteFieldClick = (boss: string, note: string) => {
  //   console.log('click')
  //   CopyToClipboard(note)
  //   enqueueSnackbar(`Copied ${boss} Note`, { preventDuplicate: true })
  // }

  const generateNote = async (unparsedPlayerList: string) => {
    const preparedPlayerList = unparsedPlayerList
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line !== '')
    // safe to assume it requires 20 people here? if less error?
    if (preparedPlayerList.length == 0) {
      enqueueSnackbar(`No players provided, copy them into the list.`, {
        preventDuplicate: true,
        variant: 'error',
      })
      setNotes(defaultNotes)
      return
    }
    // if (preparedPlayerList.length < 20 || preparedPlayerList.length > 20) {
    //   enqueueSnackbar(`Not exatly 20 players in the list, notes might not be optimal!`, {
    //     preventDuplicate: true,
    //     variant: 'info',
    //   })
    // }
    const generateNotes = await GenerateNotes(preparedPlayerList, token || '')
    if (generateNotes && generateNotes.length > 0) {
      console.log(generateNotes)
      setNotes(generateNotes)
    } else {
      setNotes(defaultNotes)
    }
  }

  const getNoteFields = (notes: Note[]) => {
    return (
      <>
        {notes.map((note) => (
          <Box key={note.boss}>
            {/* <Button onClick={() => handleNoteFieldClick(note.boss, note.note)}>Copy Note</Button> */}
            <TextField
              id="outlined-multiline-static"
              label={note.boss}
              multiline
              rows={Math.max(4, note.lines)}
              value={note.note}
              sx={{ marginTop: 2 }}
              fullWidth={true}
            />
          </Box>
        ))}
      </>
    )
  }

  return (
    <>
      <Grid
        maxWidth={1500}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2 }}
        sx={{ minHeight: '100vh', margin: '0 auto' }}
      >
        <Grid size={2}>
          <Column>
            <Button onClick={() => generateNote(playerList)} endIcon={<PostAddIcon />} fullWidth>
              Generate Notes
            </Button>
            <TextField
              id="outlined-multiline-static"
              label="Characters"
              multiline
              rows={25}
              defaultValue={`Paste the characters...`}
              sx={{ marginTop: 2 }}
              onChange={(e) => setPlayerList(e.target.value)}
            />
          </Column>
        </Grid>
        <Grid size={9}>
          <Column>
            <Stack spacing={2}>{getNoteFields(notes)}</Stack>
          </Column>
        </Grid>
      </Grid>
    </>
  )
}
export default Note
