import axios from 'axios'
import { createRikNote } from './Bosses/RikReverb'
// import { createStixNote } from './Bosses/Stix'
// import { createLockenstockNote } from './Bosses/Lockenstock'
import { createBanditNote } from './Bosses/Bandit'
import { createMugZeeNote } from './Bosses/MugZee'
import { createGallywixNote } from './Bosses/Gallywix'

function makeNotes(playerInfo: PlayerInfo[]): Note[] {
  const notes: Note[] = []
  if (playerInfo.length === 0) {
    return notes
  }
  notes.push(createRikNote([...playerInfo]))
  //   notes.push(createStixNote([...playerInfo]))
  //   notes.push(createLockenstockNote([...playerInfo]))
  notes.push(createBanditNote([...playerInfo]))
  notes.push(createMugZeeNote([...playerInfo]))
  notes.push(createGallywixNote([...playerInfo]))
  return notes
}

export async function GenerateNotes(playerList: string[], token: string): Promise<Note[]> {
  const host = process.env.REACT_APP_API_URL
  let requestString = `${host}api/character?date=${new Date().toISOString()}`
  playerList.forEach((player) => {
    if (player.includes('-')) {
      // handle unparsed realms
      player = player.replace(/-/g, '-')
    }
    requestString += `&character[]=${player}`
  })
  try {
    const echoclientReturn = await axios
      .get(requestString, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const createdNotes = makeNotes(response.data.characters || [])
        return createdNotes
      })
    return echoclientReturn
  } catch (error) {
    console.error('Failed to generate notes: ', error)
    return []
  }
}
