import React, { useState, useEffect } from 'react'
import { InstanceContext, DifficultyContext } from '../../contexts/Context'
import { useParams, useNavigate } from 'react-router-dom'
import MainMenu from '../../components/MainMenu/MainMenu'
import BossMenu from '../../components/BossMenu/BossMenu'
import BossJournal from '../../components/BossJournal/BossJournal'
import './Journal.css'
import { useQuery } from '@tanstack/react-query'
import { getBuilds } from '../../queries/Builds'

function Journal() {
  let storedActiveInstance = 1296 // 1273 = Nerub-ar, 1296 = Liberation of Undermine
  const itemActiveInstance = localStorage.getItem('activeInstance')
  if (itemActiveInstance) {
    try {
      storedActiveInstance = JSON.parse(itemActiveInstance)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [activeInstance, setActiveInstance] = useState<number>(Number(storedActiveInstance))
  useEffect(() => {
    localStorage.setItem('activeInstance', JSON.stringify(activeInstance))
  }, [activeInstance])

  let storedDifficulty = 16
  const itemDifficulty = localStorage.getItem('difficulty')
  if (itemDifficulty) {
    try {
      storedDifficulty = JSON.parse(itemDifficulty)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }

  let storedDifficulties: number[] = [14, 15, 16, 17]
  const itemDifficulties = localStorage.getItem('difficulties')
  if (itemDifficulties) {
    try {
      storedDifficulties = JSON.parse(itemDifficulties) as number[]
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [difficulty, setDifficulty] = useState<number>(storedDifficulty)
  const [difficulties, setDifficulties] = useState<number[]>(storedDifficulties)

  const navigate = useNavigate()
  const { bossid, difficulty: diffParam, buildguid } = useParams()

  const { data } = useQuery({
    queryKey: ['builds'],
    queryFn: getBuilds,
  })

  useEffect(() => {
    if (data) {
      const defaultBuild = data && data[0] && data[0].value // Latest build
      const defaultDiff = diffParam || 16 // Mythic
      const defaultBossId = bossid || 2639 // Vexie and the Geargrinders

      const isBuildKnown = data && data.some((option) => option.value === buildguid)
      if (diffParam === undefined || diffParam == null) {
        navigate(`/journal/${defaultBossId}/${defaultDiff}/${defaultBuild}`)
      } else if (!isBuildKnown || buildguid === undefined || buildguid == null) {
        navigate(`/journal/${defaultBossId}/${defaultDiff}/${defaultBuild}`)
      } else if (bossid === undefined || bossid == null) {
        navigate(`/journal/${defaultBossId}/${defaultDiff}/${defaultBuild}`)
      }
    }
  }, [bossid, diffParam, buildguid, navigate, data])

  return (
    <div id="wrapper">
      <InstanceContext.Provider value={[activeInstance, setActiveInstance]}>
        <DifficultyContext.Provider value={[difficulty, setDifficulty, difficulties, setDifficulties]}>
          <MainMenu />
          <BossMenu />
          <BossJournal />
        </DifficultyContext.Provider>
      </InstanceContext.Provider>
    </div>
  )
}

export default Journal
