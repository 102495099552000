import React from 'react'
import { useParams } from 'react-router-dom'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { Container } from '@mui/material'

function SingleVod() {
  const { vodId } = useParams()
  const videoId = vodId || ''
  return (
    <Container maxWidth="xl">
      <LiteYouTubeEmbed id={videoId} poster="maxresdefault" title="YouTube video player" />
    </Container>
  )
}

export default SingleVod
