import React from 'react'
import { Container, Grid, Card, Button, Typography, Box, Stack, Paper } from '@mui/material'
import { Download as DownloadIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme.spacing(1),
  minWidth: '300px',
}))

export function EchoSpeak() {
  return (
    <Container maxWidth="lg">
      <Box style={{ textAlign: 'center' }} m={2}>
        <Typography variant="h1">Echo Speak Guide</Typography>
      </Box>
      <Stack direction="row" gap={2}>
        <Box>
          <iframe
            width={450}
            height={253}
            src="https://www.youtube.com/embed/z9IwyfqeFpY"
            title="Echo Audio Guide"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Box>
        <Grid container direction="column" gap={2}>
          <Grid item xs>
            <Item>1. Close TS and OBS</Item>
          </Grid>
          <Grid item xs>
            <Item style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
              <Typography variant="body2">2. Download the file</Typography>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                href={`${process.env.PUBLIC_URL}/guides/EchoSpeak.dll`}
                download="EchoSpeak.dll"
              >
                EchoSpeak.dll (feb &apos;25)
              </Button>
            </Item>
          </Grid>
          <Grid item xs>
            <Item>
              3. Place it under &apos;%appdata%\TS3Client\plugins&apos;
              <br /> <br />
              Example Route: &apos;C:\Users\USER\AppData\Roaming\TS3Client\plugins&apos;
            </Item>
          </Grid>
          <Grid item xs>
            <Item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="body2" style={{ alignSelf: 'flex-start' }}>
                4. Open OBS and select Tools
              </Typography>
              <Stack>
                <Paper elevation={0}>
                  <img src={`${process.env.PUBLIC_URL}/guides/tools_dropdown.png`} alt="Tools Dropdown" />
                </Paper>
              </Stack>
            </Item>
          </Grid>

          <Grid item xs>
            <Item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="body2" style={{ alignSelf: 'flex-start' }}>
                5. Open websocket settings (DISABLE System Tray Alerts and Debug and ENABLE WebSocket)
              </Typography>
              <Stack>
                <Paper elevation={0}>
                  <img src={`${process.env.PUBLIC_URL}/guides/websocket_enable.png`} alt="Websockat Enable" />
                </Paper>
              </Stack>
            </Item>
          </Grid>

          <Grid item xs>
            <Item>6. Click &quot;Show connect info&quot; locate your Server Password COPY IT</Item>
          </Grid>
          <Grid item xs>
            <Item>7. Open TS </Item>
          </Grid>
          <Grid item xs>
            <Item>8. type /echo setpwd your WEBSOCKET PW</Item>
          </Grid>
          <Grid item xs>
            <Item>9. type /echo list {'->'} locate the channels you want to mute when it happens </Item>
          </Grid>
          <Grid item xs>
            <Item>10. /echo setmute 1,2,3 (whatever channels you wanna mute)</Item>
          </Grid>
          <Grid item xs>
            <Item>11. /echo setgmute X (only pick the ad channel)</Item>
          </Grid>
          <Grid item xs>
            <Item>12. test it out by going to a muted channel</Item>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}
