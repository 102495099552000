import React from 'react'
import './Hotfixes.css'
import { HotfixTable } from './HotfixTable'
import { useQuery } from '@tanstack/react-query'
import { GetHotfixes } from '../../queries/Hotfixes'

function Hotfixes() {
  //  get page from query string or 1
  const page = 1
  const { data, isLoading } = useQuery({
    queryKey: ['hotfixes', page],
    queryFn: () => GetHotfixes(page || 1),
  })

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (!data) {
    return <div>Failed to load hotfixes</div>
  }
  return <HotfixTable rows={data} />
}
export default Hotfixes
