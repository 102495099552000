import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import './Builds.css'
import { useQuery } from '@tanstack/react-query'
import { getBuilds } from '../../queries/Builds'

function Builds() {
  const navigate = useNavigate()
  const { bossid, difficulty, buildguid, diffbuildguid } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ['builds'],
    queryFn: getBuilds,
  })
  const activeBuild = data && data.find((option) => option.value === buildguid)
  const diffBuild = data && data.find((option) => option.value === diffbuildguid)

  const updateBuild = (selected: Build | null) => {
    if (!selected) {
      return
    }
    const newBuild = selected.value
    if (diffbuildguid) {
      navigate(`/journal/${bossid}/${difficulty}/${newBuild}/${diffbuildguid}`)
    } else {
      navigate(`/journal/${bossid}/${difficulty}/${newBuild}`)
    }
  }

  const updateCompareBuild = (selected: Build | null) => {
    const selectedDiffBuild = selected ? selected.value : null
    if (!selectedDiffBuild || selectedDiffBuild === buildguid) {
      navigate(`/journal/${bossid}/${difficulty}/${buildguid}`)
    } else {
      navigate(`/journal/${bossid}/${difficulty}/${buildguid}/${selectedDiffBuild}`)
    }
  }

  return (
    <>
      <Select
        className="builds-select-main"
        styles={{
          input: (styles) => ({ ...styles, color: '#cad1d8' }),
          placeholder: (styles) => ({ ...styles, color: '#cfd1d3' }),
          singleValue: (styles) => ({ ...styles, color: '#cad1d8' }),
          control: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
            borderColor: '#333',
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            color: '#cad1d8',
            backgroundColor: isSelected ? '#31363c' : isFocused ? '#31363c' : '#22262c',
            ':active': {
              backgroundColor: isSelected ? '#31363c' : '#31363c',
            },
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
          menuList: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
        }}
        isClearable={false}
        isSearchable={true}
        onChange={updateBuild}
        options={data}
        value={activeBuild || null}
        isLoading={isLoading}
      />
      <Select
        className="builds-select-main"
        styles={{
          input: (styles) => ({ ...styles, color: '#cad1d8' }),
          placeholder: (styles) => ({ ...styles, color: '#cfd1d3' }),
          singleValue: (styles) => ({ ...styles, color: '#cad1d8' }),
          control: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
            borderColor: '#333',
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            color: '#cad1d8',
            backgroundColor: isSelected ? '#31363c' : isFocused ? '#31363c' : '#22262c',
            ':active': {
              backgroundColor: isSelected ? '#31363c' : '#31363c',
            },
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
          menuList: (styles) => ({
            ...styles,
            backgroundColor: '#22262c',
          }),
        }}
        isClearable={true}
        isSearchable={true}
        onChange={updateCompareBuild}
        options={data}
        value={diffBuild || null}
        placeholder="Select a build to compare"
        isLoading={isLoading}
      />
    </>
  )
}

export default Builds
