import { GetClassInfo } from './../ClassInfo'

const noteLines = [
  '#int-466834-231978-2 {skull} [|cff3fc7ebHopefulgg|r |cff8788eeXerwofour|r ]',
  '#int-466834-231978-2 {cross} [|cff3fc7ebGingipumper|r |cffaad372Solhunti|r ]',
  '#int-466834-231978-2 {square} [|cff3fc7ebWexicc|r |cff33937fMaydatory|r ]',
  '#int-466834-231978-2 {moon} [|cff0070ddStoveflorist|r |cffc69b6dRevvezj|r ]',
  '',
  '#sooth {star} |cff00ff98Clickzmonk|r |cffff7c0aHankstick|r ',
  '#sooth {circle} |cfffff468Perfectob|r |cffaad372Solhunti|r ',
  '#sooth {diamond} |cff00ff98Zhatzimw|r |cff00ff98Velomunk|r ',
  '',
  '#cones1 |cfff48cbaTiefpalxdd|r |cfffff468Perfectob|r |cffff7c0aHankstick|r |cffaad372Solhunti|r |cffffffffSodapthree|r ',
  '#cones2 |cffc69b6dMeêres|r |cff33937fMaydatory|r |cff00ff98Velomunk|r |cffffffffRycncaught|r |cff8788eeXerwofour|r ',
  '#conesbackup1 |cffa330c9Kushiwushi|r |cffc69b6dRevvezj|r ',
  '#conesbackup2 |cff00ff98Clickzmonk|r |cffc69b6dWallifexd|r',
  '',
  'Soak Group 1 |cff3fc7ebGingipumper|r |cff3fc7ebHopefulgg|r |cff3fc7ebWexicc|r ',
  'Soak Group 2 |cffaad372Solhunti|r |cff8788eeXerwofour|r |cff33937fMaydatory|r ',
  'Soak Group 3 |cffffffffSodapthree|r |cff00ff98Clickzmonk|r |cff0070ddStoveflorist|r ',
  'Soak Group 4 |cffc69b6dRevvezj|r |cffc69b6dWallifexd|r |cffa330c9Kushiwushi|r |cffa330c9Nicememesdhc|r ',
  '',
  '#bombs |cff3fc7ebGingipumper|r |cff3fc7ebHopefulgg|r |cffa330c9Nicememesdhc|r |cff3fc7ebWexicc|r ',
  '',
  '#echoCC start:1.5,SCS:1218696:1 end:20,SCS:1218696:1 Clickzmonk:119381,2,SCS:471352 Nicememesdhc:179057,2,SCS:471352 Kushiwushi:179057,2,SCS:471352',
  '#echoCC start:1.5,SCS:1218696:5 end:20,SCS:1218696:5 Clickzmonk:119381,2,SCS:471352 Nicememesdhc:179057,2,SCS:471352 Kushiwushi:179057,2,SCS:471352',
]

export function createGallywixNote(playerInfo: PlayerInfo[]): Note {
  let note = ''
  const newNoteLines = [...noteLines]
  for (let i = 0; i < newNoteLines.length; i++) {
    // replace $player with player name and then remove from playerlist, this happens multiple times per line so we need to move along in the playerlist
    while (newNoteLines[i].indexOf('$player') !== -1 && playerInfo.length > 0) {
      const playerName = playerInfo[0].name
      const playerClassHex = GetClassInfo(playerInfo[0].classId).hexColor
      const playerNoteString = `|c${playerClassHex}${playerName}|r`
      newNoteLines[i] = newNoteLines[i].replace('$player', playerNoteString)
      playerInfo.splice(0, 1)
    }
    note += newNoteLines[i] + '\n'
  }
  return { boss: 'Gallywix', note: note, lines: newNoteLines.length }
}
