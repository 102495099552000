export const getBoss: (activeBuild: string, bossId: number, difficulty: number) => Promise<Journal> = async (
  activeBuild: string,
  bossId: number,
  difficulty: number
) => {
  const host = process.env.REACT_APP_API_URL
  const response = await fetch(host + activeBuild + '/boss/' + bossId + '/' + difficulty)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return await response.json()
}
