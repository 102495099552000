import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { InstanceContext, DifficultyContext } from '../../contexts/Context'
import { Grid, Modal, Box, Typography } from '@mui/material'

function BossMenu() {
  const navigate = useNavigate()
  const { difficulty, buildguid, bossid, diffbuildguid } = useParams()
  const [activeInstance] = useContext(InstanceContext)
  const [bosses, setBosses] = useState([])
  const [, , , setDifficulties] = useContext(DifficultyContext)

  const [modalOpen, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)
  type InstanceItems = {
    id: number
    name: string
    items: number[]
  }
  const [instanceItems, setInstanceItems] = useState<InstanceItems[]>([])

  useEffect(() => {
    if (!buildguid || !activeInstance) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + buildguid + '/instance/' + activeInstance)
      .then((response) => response.json())
      .then((data) => {
        const bosses = data.bosses
        bosses.sort((a: any, b: any) => a.orderindex - b.orderindex)
        setBosses(bosses)

        const difficulties = data.difficulties
        setDifficulties(difficulties)
      })
      .catch((err) => {
        console.log(err.message)
      })
    fetch(host + buildguid + '/instance/items/' + activeInstance)
      .then((response) => response.json())
      .then((data) => {
        const dataArray = Object.values(data) as InstanceItems[]
        setInstanceItems(dataArray)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [activeInstance, buildguid, setDifficulties])

  function BossList(bosses: Boss[]) {
    const clicked = (id: string) => {
      let bossClicked = id
      if (id === bossid) {
        bossClicked = '0' // 0 means no boss showing
      }
      if (!diffbuildguid) {
        navigate(`/journal/${bossClicked}/${difficulty}/${buildguid}`)
      } else {
        navigate(`/journal/${bossClicked}/${difficulty}/${buildguid}/${diffbuildguid}`)
      }
    }
    return bosses.map((boss) => (
      <button
        key={boss.id}
        onClick={() => clicked(boss.id)}
        className={`bossButtons ${Number(boss.id) === Number(bossid) ? 'active' : ''}`}
      >
        {boss.name_lang}
      </button>
    ))
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
    lineheight: 1.5,
  }

  function ItemsModal() {
    return (
      <div>
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {instanceItems && instanceItems.length > 0 ? (
              instanceItems.map((boss) => (
                <div key={boss.name}>
                  <Typography variant="h6" component="h2">
                    {boss.name}
                  </Typography>
                  {boss.items.map((itemId) => (
                    <Grid container key={itemId} ml={2}>
                      <Grid item mr={2}>
                        {itemId}
                      </Grid>
                      {/* <Grid item>
                        <Link href={`https://example.com/item/${itemId}`} target="_blank" rel="noopener">
                          View Item
                        </Link>
                      </Grid> */}
                    </Grid>
                  ))}
                </div>
              ))
            ) : (
              <Typography variant="h6" component="h2">
                No items available
              </Typography>
            )}
          </Box>
        </Modal>
      </div>
    )
  }

  return (
    <>
      <div id="bosses">
        {Array.isArray(bosses) && BossList(bosses)}
        <button className="showItemsButton" onClick={() => handleModalOpen()}>
          Show Items
        </button>
        <ItemsModal />
      </div>
    </>
  )
}

export default BossMenu
