import React, { useContext } from 'react'
import { Menu, MenuItem, MenuButton, MenuRadioGroup } from '@szhsin/react-menu'
import { useParams, useNavigate } from 'react-router-dom'
import { RadioChangeEvent } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import '@szhsin/react-menu/dist/theme-dark.css'
import { DifficultyContext } from '../../../contexts/Context'

type DifficultyMap = {
  [key: number]: string
}

function DifficultyDropdown() {
  const navigate = useNavigate()
  const { bossid, buildguid } = useParams()
  const [difficulty, setDifficulty, difficulties] = useContext(DifficultyContext)

  const difficultyMap: DifficultyMap = {
    1: 'Normal',
    2: 'Heroic',
    3: '10 Player',
    4: '25 Player',
    5: '10 Player (Heroic)',
    6: '25 Player (Heroic)',
    7: 'Looking For Raid',
    8: 'Mythic+',
    9: 'Timewalking',
    10: '40 Player',
    11: 'Heroic Scenario',
    12: 'Normal Scenario',
    13: 'Challenge Mode',
    14: 'Normal',
    15: 'Heroic',
    16: 'Mythic',
    17: 'LFR',
    18: 'Looking For Raid (Legacy)',
    19: 'Timewalking',
    20: '25 Player (Mythic)',
    21: '5 Player (Mythic)',
    22: '5 Player (Mythic) (Legacy)',
    23: '40 Player (Legacy)',
    24: '10 Player (Legacy)',
    25: '25 Player (Legacy)',
    26: 'Normal (Legacy)',
    220: 'Story Mode',
  }

  const handleDifficultyChange = (event: RadioChangeEvent) => {
    const newDifficulty = Number(event.value)
    setDifficulty(newDifficulty)
    navigate(`/journal/${bossid}/${newDifficulty}/${buildguid}`)
  }

  return (
    <Menu menuButton={<MenuButton>{difficultyMap[difficulty]}</MenuButton>} arrow={true} theming={'dark'}>
      <MenuRadioGroup value={difficulty} onRadioChange={(e) => handleDifficultyChange(e)}>
        {difficulties.map((diff) => {
          if (!difficultyMap[diff]) {
            return null
          }
          return (
            <MenuItem type="radio" key={diff} value={diff}>
              {difficultyMap[diff]}
            </MenuItem>
          )
        })}
      </MenuRadioGroup>
    </Menu>
  )
}

export default DifficultyDropdown
