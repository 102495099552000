import React, { useContext } from 'react'
import DOMPurify from 'dompurify'
import SpellTag from '../../SpellTags/SpellTag'
import { HiddenSectionsContext } from '../../../contexts/Context'

function JournalSectionDescription({ section }: { section: any }) {
  const [hiddenSections] = useContext(HiddenSectionsContext)
  const hidden = hiddenSections[section.id] || false
  const spellFlags = section.spellFlags
  spellFlags.sort((a: [number, number], b: [number, number]) => b[1] - a[1])

  return (
    <div>
      {section.bodytext_lang !== '' && (
        <div className={`bodytext_lang ${hidden ? 'hidden' : ''}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(section.bodytext_lang, {
                ADD_ATTR: ['target', 'rel'],
              }),
            }}
          />
          {spellFlags.length > 0 && <SpellTag tags={spellFlags} />}
        </div>
      )}
    </div>
  )
}

export default JournalSectionDescription
