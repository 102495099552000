export const CopyToClipboard = (text: string, event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
  if (event) {
    event.stopPropagation() // Prevent the event from propagating to parent elements
  }

  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(text.toString()).catch((err) => {
      console.error('Failed to copy text: ', err)
    })
  } else {
    console.error('Failed to copy text, isSecureContext is false')
  }
}
