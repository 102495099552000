import React, { useState, useEffect } from 'react'
import { diff } from 'deep-diff'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import CustomTitle from './CustomTitle' // Import the custom title component
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getBoss } from '../../queries/Boss'

function DiffView({ journal }: { journal: Journal | undefined }): any {
  const { difficulty, buildguid, bossid, diffbuildguid } = useParams()
  const [diffbuildguidData, setCompareBuildData] = useState<any[] | null>(null)

  const { data } = useQuery({
    queryKey: ['bossData', diffbuildguid, bossid, difficulty],
    queryFn: () => getBoss(diffbuildguid as string, Number(bossid), Number(difficulty)),
    enabled: !!diffbuildguid && Number(bossid) !== 0 && !!Number(difficulty),
  })

  function findSectionInfo(obj: any, path: string[]): [string, number] {
    let current: any = obj
    const parents: any[] = []
    const keys: string[] = []

    for (let i = 0; i < path.length; i++) {
      if (current[path[i]] === undefined) {
        return ['Unkown Title', 0]
      }
      parents.push(current)
      keys.push(path[i])
      current = current[path[i]]
    }

    while (parents.length > 0) {
      if (Object.prototype.hasOwnProperty.call(current, 'title_lang')) {
        return [current.title_lang, current.id]
      }
      current = parents.pop()
      keys.pop()
    }
    return ['Unkown Title', 0]
  }

  useEffect(() => {
    setCompareBuildData(null)
    if (!diffbuildguid || !journal || !data) {
      return
    } else if (diffbuildguid === buildguid) {
      return
    }

    function sortSpellFlags(obj: any) {
      for (const key in obj) {
        const value = obj[key]
        if (value.spellFlags) {
          value.spellFlags.sort((a: [number, string], b: [number, string]) => {
            if (a[1] === b[1]) {
              return String(a[0]).localeCompare(String(b[0]))
            }
            return Number(a[1]) - Number(b[1])
          })
        }
        if (value.child) {
          sortSpellFlags(value.child)
        }
      }
    }

    sortSpellFlags(data.journalSection)
    sortSpellFlags(journal.journalSection)

    const differences = diff(journal, data, (path, key) => {
      if (path.includes('spellFlags')) {
        return key === 1
      }
      return ['buildguid', 'journalsectionxdifficulty', 'iconfile'].includes(key)
    })
    interface Differences {
      lhs: string
      rhs: string
      nameLang: string
      sectionId?: number
    }
    if (differences) {
      const filteredDifferences = differences.filter((difference: any) => {
        const lhs = difference.lhs === undefined ? '' : difference.lhs
        const rhs = difference.rhs === undefined ? '' : difference.rhs
        return !(typeof lhs === 'number' || typeof rhs === 'number' || lhs === rhs) || difference.kind === 'A'
      })

      const mappedDifferences = filteredDifferences.map((difference: any): Differences => {
        const [nameLang, sectionId] = findSectionInfo(journal, difference.path as string[])
        if (difference.kind === 'E') {
          const lhs = difference.lhs.replace(/<br\s*\/?>/gi, '\n')
          const rhs = difference.rhs.replace(/<br\s*\/?>/gi, '\n')

          return {
            lhs: lhs || '',
            rhs: rhs || '',
            nameLang: nameLang,
            sectionId: sectionId,
          }
        } else if (difference.kind === 'A') {
          const kind = difference.item.kind
          return {
            lhs: kind === 'D' ? difference.item.lhs[0] : '',
            rhs: kind === 'N' ? difference.item.rhs[0] : '',
            nameLang: nameLang,
            sectionId: sectionId,
          }
        } else if (difference.kind === 'D') {
          return {
            lhs: difference.lhs[0].bodytext_lang,
            rhs: '',
            nameLang: `New: ${nameLang}`,
            sectionId: sectionId,
          }
        } else if (difference.kind === 'N') {
          return {
            lhs: '',
            rhs: difference.rhs[0].bodytext_lang,
            nameLang: `Deleted: ${nameLang}`,
            sectionId: sectionId,
          }
        }

        return {
          lhs: '',
          rhs: '',
          nameLang: 'Unkown Change, Kind: ' + difference.kind,
          sectionId: sectionId,
        }
      })
      const cleanedDifferences = mappedDifferences.map((difference: Differences) => {
        const lhs = typeof difference.lhs === 'string' ? difference.lhs.replace(/<[^>]*>/g, '') : difference.lhs
        const rhs = typeof difference.rhs === 'string' ? difference.rhs.replace(/<[^>]*>/g, '') : difference.rhs

        return {
          sectionId: difference.sectionId,
          nameLang: difference.nameLang,
          lhs: lhs,
          rhs: rhs,
        }
      })
      setCompareBuildData(cleanedDifferences)
    } else {
      setCompareBuildData([])
    }
  }, [diffbuildguid, buildguid, bossid, difficulty, journal, data])

  const newDiffStyles = {
    variables: {
      dark: {
        diffViewerTitleColor: '#cad1d8',
        addedBackground: '#158996',
        wordAddedBackground: '#08a31d',
      },
    },
    titleBlock: {
      fontSize: '1.2em',
    },
  }

  return (
    <>
      {diffbuildguid ? (
        journal && diffbuildguidData && diffbuildguidData[0] ? (
          <div id="comparedBuilds">
            {diffbuildguidData.map((entry: any, index: number) => (
              <ReactDiffViewer
                styles={newDiffStyles}
                key={index}
                oldValue={entry.rhs}
                newValue={entry.lhs}
                splitView={false}
                hideLineNumbers={true}
                useDarkTheme={true}
                leftTitle={
                  <CustomTitle title={entry.nameLang} sections={journal.journalSection} sectionId={entry.sectionId} />
                } // Use the custom title component
                showDiffOnly={true}
                extraLinesSurroundingDiff={0}
                compareMethod={DiffMethod.WORDS}
              />
            ))}
          </div>
        ) : diffbuildguidData && journal ? (
          <div id="diffLoading">No Changes Detected!</div>
        ) : (
          <div id="diffLoading">
            {diffbuildguid === buildguid ? "You've got the same build selected. >_>" : 'Loading Comparison...'}
          </div>
        )
      ) : null}
    </>
  )
}

export default DiffView
