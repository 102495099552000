import React, { useState } from 'react'
import { Container, Typography, Backdrop, CircularProgress, Box, FormControlLabel, Checkbox } from '@mui/material'
import { PrivateAurasTable } from './PrivateAurasTable'
import { useQuery } from '@tanstack/react-query'
import { getPrivateAuras } from '../../queries/PrivateAuras'

export function PrivateAuras() {
  const [showOldContent, setShowOldContent] = useState(false)

  const { data, isLoading, error } = useQuery({
    queryKey: ['privateAura'],
    queryFn: getPrivateAuras,
  })

  if (error) {
    return (
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'top',
          height: '100vh',
          mt: 2,
        }}
      >
        <Box>
          <Typography align="center">There was an error loading the private auras:</Typography>
          <Typography align="center">{error.message}</Typography>
        </Box>
      </Container>
    )
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOldContent(event.target.checked)
  }

  return (
    <Container maxWidth="xl" sx={{ position: 'relative', minHeight: '300px' }}>
      {!isLoading && data && (
        <>
          <Typography variant="h1" align="center" sx={{ m: 2 }}>
            Private Auras
            <FormControlLabel
              sx={{ ml: 5 }}
              control={<Checkbox checked={showOldContent} onChange={handleChange} />}
              label="Show Old Content"
            />
          </Typography>
          <Box display="flex" justifyContent="center">
            <PrivateAurasTable showOldContent={showOldContent} privateAuras={data} />
          </Box>
        </>
      )}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          top: '50%',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0)',
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}
