import * as React from 'react'
import { Button, AppBar, Box, Toolbar, Container, Menu, MenuItem, Grid2 as Grid } from '@mui/material'
import { Logo } from '../components/Logo'
import { Logout as LogoutIcon, LiveHelp as LiveHelpIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
const cookieName = 'echoServicesAuthToken'
const domain = process.env.NODE_ENV === 'development' ? 'localhost' : 'echoesports.gg'

export function TopMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const logout = () => {
    Cookies.remove(cookieName, { domain })
    window.location.reload()
  }

  const buttonSx = {
    color: 'white',
    display: 'block',
    textAlign: 'center',
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar variant="dense">
          <Button href="/">
            <Logo width={30} height={30} fill="#ffffff" />
          </Button>
          <Grid
            container
            spacing={0.5}
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              alignSelf: 'left',
            }}
          >
            <Grid>
              <Button href="/" sx={buttonSx}>
                Journal
              </Button>
            </Grid>
            <Grid>
              <Button href="/private-auras" sx={buttonSx}>
                Private Auras
              </Button>
            </Grid>
            <Grid>
              <Button href="/hotfixes" sx={buttonSx}>
                Hotfixes
              </Button>
            </Grid>
            <Grid>
              <Button href="/note" sx={buttonSx}>
                Note
              </Button>
            </Grid>
            {/* <Button href="/vods" sx={buttonSx}>
              VODS
            </Button> */}
          </Grid>
          <Grid container spacing={0.5} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <Grid>
              <Button
                id="guides-button"
                aria-controls={open ? 'guides-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ lineHeight: '2em', color: 'white', display: 'block' }}
              >
                <Box
                  sx={{
                    marginRight: '3px',
                    float: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LiveHelpIcon />
                </Box>
                Guides
              </Button>
              <Menu
                id="guides-menu"
                aria-labelledby="guides-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleClose}>
                  <Link style={{ color: 'white', textDecoration: 'none' }} to="/echo-speak-guide">
                    Echo Speak
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link style={{ color: 'white', textDecoration: 'none' }} to="/symlink">
                    Symlink
                  </Link>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid>
              <Button id="logout" onClick={logout} sx={{ lineHeight: '2em', color: 'white', display: 'block' }}>
                <Box sx={{ float: 'left', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <LogoutIcon />
                </Box>
                Logout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
