import React, { useState, useEffect } from 'react'
import { HiddenSectionsContext } from '../../contexts/Context'
import CollapseButton from './CollapseButton'
import Section from './Section/Section'
import DiffView from './DiffView'
import './BossJournal.css'
import { useQuery } from '@tanstack/react-query'
import { getBoss } from '../../queries/Boss'
import { useParams } from 'react-router-dom'

function BossJournal() {
  let storedHiddenSections: HiddenSections = {}
  const item = localStorage.getItem('hiddenSections')
  if (item) {
    try {
      storedHiddenSections = JSON.parse(item) as HiddenSections
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [hiddenSections, setHiddenSections] = useState<HiddenSections>(storedHiddenSections)
  useEffect(() => {
    localStorage.setItem('hiddenSections', JSON.stringify(hiddenSections))
  }, [hiddenSections])

  const toggleHidden = (id: number) => {
    const idString = id.toString()
    setHiddenSections((prevState: { [key: string]: boolean }) => ({
      ...prevState,
      [id]: !prevState[idString],
    }))
  }

  const { bossid: bossId, difficulty, buildguid: activeBuild } = useParams()
  const { data: journal, isLoading } = useQuery({
    queryKey: ['bossData', activeBuild, bossId, difficulty],
    queryFn: () => getBoss(activeBuild as string, Number(bossId), Number(difficulty)),
    enabled: !!activeBuild && Number(bossId) !== 0 && !!Number(difficulty),
  })

  return (
    <HiddenSectionsContext.Provider value={[hiddenSections, toggleHidden]}>
      <div id="journal">
        {isLoading ? (
          <div id="encounterTitle">Loading...</div>
        ) : journal !== undefined ? (
          <>
            <div id="encounterTitle">
              <CollapseButton sections={journal.journalSection} />
              <span className="name_lang">{journal.name_lang}</span>
              <span className="encounter_id">({journal.dungeonencounterid})</span>
            </div>
            <Section sections={journal.journalSection} />
          </>
        ) : (
          <div id="encounterTitle">No Boss Selected or Error</div>
        )}
      </div>
      <DiffView journal={journal} />
    </HiddenSectionsContext.Provider>
  )
}

export default BossJournal
