type classInfo = {
  class: string
  hexColor: string
}

export function GetClassInfo(classId: number): classInfo {
  switch (classId) {
    case 1:
      return { class: 'WARRIOR', hexColor: 'ffc69b6d' }
    case 2:
      return { class: 'PALADIN', hexColor: 'fff38bb9' }
    case 3:
      return { class: 'HUNTER', hexColor: 'ffaad372' }
    case 4:
      return { class: 'ROGUE', hexColor: 'fffff468' }
    case 5:
      return { class: 'PRIEST', hexColor: 'ffffffff' }
    case 6:
      return { class: 'DEATHKNIGHT', hexColor: 'ffc31d39' }
    case 7:
      return { class: 'SHAMAN', hexColor: 'ff0070dd' }
    case 8:
      return { class: 'MAGE', hexColor: 'ff3fc7eb' }
    case 9:
      return { class: 'WARLOCK', hexColor: 'ff8788ee' }
    case 10:
      return { class: 'MONK', hexColor: 'ff00ff98' }
    case 11:
      return { class: 'DRUID', hexColor: 'ffff7c0a' }
    case 12:
      return { class: 'DEMONHUNTER', hexColor: 'ffa330c9' }
    case 13:
      return { class: 'EVOKER', hexColor: 'ff33937f' }
    default:
      return { class: 'MISSING', hexColor: 'ffffffff' }
  }
}
