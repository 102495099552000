import { GetClassInfo } from './../ClassInfo'
const noteLines = [
  '#amp {star} [$player $player]',
  '#amp {circle} [$player $player]',
  '#amp {diamond} [$player $player]',
  '#amp {triangle} [$player $player]',
  '#amp {moon} [$player $player]',
  '#amp {square} [$player $player]',
]

export function createRikNote(playerInfo: PlayerInfo[]): Note {
  let note = ''
  const newNoteLines = [...noteLines]
  for (let i = 0; i < newNoteLines.length; i++) {
    // replace $player with player name and then remove from playerlist, this happens multiple times per line so we need to move along in the playerlist
    while (newNoteLines[i].indexOf('$player') !== -1 && playerInfo.length > 0) {
      const playerName = playerInfo[0].name
      const playerClassHex = GetClassInfo(playerInfo[0].classId).hexColor
      const playerNoteString = `|c${playerClassHex}${playerName}|r`
      newNoteLines[i] = newNoteLines[i].replace('$player', playerNoteString)
      playerInfo.splice(0, 1)
    }
    note += newNoteLines[i] + '\n'
  }
  return { boss: 'Rik', note: note.trim(), lines: newNoteLines.length }
}
