import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { getDBTableName } from './DB_FILE_HASHES'

const statusMap = {
  1: 'Add/Update Record',
  2: 'Remove Record',
  3: 'Remove Hotfixes',
  4: 'Not Public',
}

const DB_TABLE_HASH_LOOKUP: Record<number, string> = getDBTableName()

export function HotfixTable({ rows }: { rows: Hotfix[] }) {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 800, margin: 'auto', marginTop: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>PushId</TableCell>
            <TableCell align="right">build</TableCell>
            <TableCell align="right">table</TableCell>
            <TableCell align="right">recordId</TableCell>
            <TableCell align="right">status</TableCell>
            <TableCell align="right">Affected Spell</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.index}
              </TableCell>
              <TableCell align="right">{row.build}</TableCell>
              <TableCell align="right">
                {DB_TABLE_HASH_LOOKUP[row.tableHash] ? DB_TABLE_HASH_LOOKUP[row.tableHash] : row.tableHash}
              </TableCell>
              <TableCell align="right">{row.recordId}</TableCell>
              <TableCell align="right">{statusMap[row.status as keyof typeof statusMap]}</TableCell>
              <TableCell align="right">TODO</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
