export const getBuilds: () => Promise<Build[]> = async () => {
  const host = process.env.REACT_APP_API_URL
  const response = await fetch(host + 'builds')
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json()
  const dropDownList = []
  for (const build in data) {
    const date = new Date(data[build].timestamp).toLocaleString()
    dropDownList.push({
      value: data[build].buildguid,
      label: '' + data[build].buildnumber + ' Time: ' + date,
    })
  }
  return dropDownList
}
